.virtual_organization_members {
    :global {
         .Shared_bottom {
            background: #fff;
            .filetypebox {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                height: 60px;
                border-bottom: 1px solid #F3F3F3;
                .resetbtn{
                    margin: 0 20px;
                }
            }
            .list_of_institutions{
                padding: 20px;
                .ant-table-wrapper{
                    .ant-table-placeholder {
                        height: calc(100vh - 401px);
                    }
                    .ant-table-body {
                        height: calc(100vh - 401px);
                    }
                }
            }
           
        }
         .but {
            position: relative;
            font-size: 14px;
            color: #3E78ED;
            padding: 0 8px;
            cursor: pointer;

            &::after {
                position: absolute;
                right: 1px;
                width: 1px;
                background: #C4C4C4;
                height: 14px;
                top: 50%;
                margin-top: -7px;
                content: "";
            }

            &:last-child::after {
                display: none;
            }
        }
    }
}