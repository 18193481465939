.breadcrumb_box {
    padding: 20px 30px 10px 30px;
    background: #ffffff;

    :global {
        .ant-breadcrumb {
            span {
                font-size: 14px;
                color: #333333;
            }

            span.pointer {
                &:hover {
                    color: #3E78ED;
                    text-decoration: underline;
                }
            }

            > span:last-of-type {
                span {
                    color: #888888;
                }
            }
        }
    }

    .breadcrumb_title {
        position: relative;
        font-size: 22px;
        line-height: 120%;
        margin: 16px 0;
        padding-left: 20px;
        color: #333;
        font-weight: 500;

        &::after {
            content: '';
            position: absolute;
            left: 0%;
            bottom: 0;
            width: 4px;
            height: 90%;
            background: linear-gradient(148.89deg, #6EA8FF 2.69%, #485AFE 113.17%);
            border-radius: 10px;
        }
    }
}