#root {
  min-width: 1280px;
  // max-width: 1600px;
  // margin: 0 auto;
  // height: 100%;
}

// 滚动条设置
::-webkit-scrollbar {
  width: 7px; //y轴上的滚动条宽度
  height: 7px; //x轴上滚动条高度
}
::-webkit-scrollbar-track {
  border-radius: 2px; /*滚动条的背景区域的圆角*/
  background-color: #fdf8f5; /*滚动条的背景颜色*/
}
::-webkit-scrollbar-thumb {
  border-radius: 2px; /*滚动条的圆角*/
  background-color: #ccc; /*滚动条的背景颜色*/
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(46, 86, 159);
  cursor: pointer;
}

::-webkit-scrollbar-thumb:active {
  background-color: rgb(46, 86, 159);
  cursor: pointer; 
}

.ant-layout-sider {
  background: #fff;
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 200;
  height: 100%;
}
html body {
  // background-color: #f0f2f5;
}
html {
  overflow-y: scroll;
  overflow-x: hidden;
}
body{
  width: 100% !important;
  overflow: auto !important;
  background: #F6F7FB;
}
p{
  margin-bottom: 0;
}
.ant-btn-primary {
  background-color: #3e78ed;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background-color: #3e78ed;
  border-color: #3e78ed;
}

.ant-table-pagination {
  li {
    html body {
      background-color: #f6f7fb;
    }

    .ant-table-pagination {
      li {
        border: none;
      }

      .ant-pagination-next,
      .ant-pagination-prev {
        a {
          border: none;
        }
      }
    }
  }
}

//删除弹窗
.ModalConfirm-pic {
  display: block;
  .ant-modal-body {
    text-align: center;
    .anticon {
      color: #3e78ed !important;
      float: initial;
      vertical-align: top;
      margin-top: 2.5px;
      margin-right: 10px;
    }
    .ant-modal-confirm-title {
      display: inline-block;
      font-size: 18px;
      line-height: 26px;
      color: #333333;
    }
    .ant-modal-confirm-content {
      margin: 15px 0 15px !important;
    }
    .ant-modal-confirm-btns {
      .ant-btn {
        padding: 8px 27px;
        background: #ffffff;
        border: 1px solid #ebebeb;
        box-sizing: border-box;
        border-radius: 5px;
        height: 39px;
        cursor: pointer;
        span {
          font-size: 16px;
          text-align: center;
          line-height: 22px;
          color: #888888;
        }
      }
      .ant-btn.ant-btn-primary,
      .ant-btn.ant-btn-danger {
        background: #3e78ed;
        border-radius: 5px;
        margin-left: 26px;
        span {
          color: #ffffff;
        }
      }
    }
  }
}
//空数据
.Empty-conet{
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height:300px;
  .img{
    display: block;
    width:200px;
    img{
      width:200px;
      height:auto;
      display: block;
      margin:0 auto;
    }
    span{
      font-family: Noto Sans SC;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 120.7%;
      color: #888888;
      display: block;
      margin-top:20px;
      text-align: center;
    }
  }
}

//媒体查询
//如果文档宽度小于 1200 则出现滚动条:
//@media screen and (max-width:1200px) {
//  .wrap-center{
//    width: 1200px;
//    margin:0 auto;
//    display: block;
//  }
//}
//@media (min-width:1300px) {
//  .wrap-center{
//    width: 1200px;
//    margin:0 auto;
//    display: block;
//  }
//}
//@media (min-width:1366px){
//  .wrap-center{
//    width: 1200px;
//    margin:0 auto;
//    display: block;
//  }
//}
//@media (min-width:1440px){
//  .wrap-center{
//    width: 1200px;
//    margin:0 auto;
//    display: block;
//  }
//}
//@media (min-width:1920px){
//  .wrap-center{
//    width: 1600px;
//    margin:0 auto;
//    display: block;
//  }
//}
.binding_modal{
  .ant-modal-body {
    height:500px !important;
    overflow:auto;
    .modaltitle{
      color: #000000;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 30px;

      &::before {
        content: "";
        width: 4px;
        height: 18px;
        display: inline-block;
        margin-right: 15px;
        background: linear-gradient(148.89deg, #6ea8ff 2.69%, #485afe 113.17%);
        border-radius: 10px;
      }
    }
  }
}

.Empty-conet{
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height:300px;
  .img{
    display: block;
    width:200px;
    img{
      width:200px;
      height:auto;
      display: block;
      margin:0 auto;
    }
    span{
      font-family: Noto Sans SC;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 120.7%;
      color: #888888;
      display: block;
      margin-top:20px;
    }
  }
}

//上传文件的loding动画

@keyframes rotation {
  from {
     transform: rotate(0deg);
  }

  to {
     transform: rotate(360deg);
  }
}

.rotateaaaaaaaaaaaaa {

  animation: rotation 1s linear infinite;

}
//全局modal 垂直水平居中
.ant-modal-wrap{
  display: flex;
  align-items: center;
  .ant-modal{
      top:0;
  }
}