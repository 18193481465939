.menu_container {
  .global_table .ant-table-thead tr th:first-child span {
    display: block;
  }

  .ul_list {
    margin-bottom: 0;
    padding-left: 10px;
    list-style: none;

    li {
      line-height: 40px;
      /*  display: flex;*/
      display: block;
      width: 100%;
      position: relative;
      list-style: none;

      span {
        display: inline-block;
      }

      /*  &:before{
        content: '';
        position: absolute;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: #888888;
        opacity: 0.9;
        left: -12px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 40px;

      }*/
      // span:nth-child(1){
      //   margin-right: 31.36%;
      // }
    }
  }

}

.add_menu {
  .ant-form-item-control {
    .ant-form-explain {
      padding-left: 0;
    }
  }
}

.publicstyle {
  .menu_select {
    padding: 20px 40px;
    background: #fff;
  }
}

.menu_role {
  i {
    font-style: normal;
  }

  li {
    list-style: none;
  }

  .menu_role_list {
    position: relative;
    background: #fff;
    min-height: 500px;
    >h2 {
      padding: 21px 51px;
      border-bottom: 1px solid #EEEEEE;
      margin-bottom: 0;
      background: #fff;

      span {
        display: flex;

        svg {
          margin-right: 7px;
          position: relative;
          top: 2px;
        }

        color: #3E78ED;
        cursor: pointer;
        font-size: 14px;
      }
    }
    .tree_loading{
      min-height: 500px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      right: 0;
      background: rgba(256,256,256,.6);
    }
  }


  .menu_role_select {
    background: #fff;
    padding: 20px 40px;
    margin-bottom: 20px;

    >div {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 21px;
      }

      >span {
        margin-right: 20px;
      }

      >div {
        display: flex;

        span {
          padding: 6px 16px;
          margin-right: 20px;
          border: 1px solid #EEEEEE;
          border-radius: 3px;
          display: flex;
          align-items: center;
          cursor: pointer;

          &.active {
            background: #3E78ED;
            border: solid 1px #3E78ED;
            color: #fff;
          }
        }

        svg {
          margin-right: 7px;
        }

        i {
          font-style: normal;
        }
      }
    }
  }
}

