.pointer {
  cursor: pointer;
}
.default{
  cursor: default;
}
//添加子专题 | 编辑 | 删除
.vertical_j {
  margin-bottom: 0;
  span:not(:last-child)::after {
    content: "|";
    margin: 0px 7px;
    color: #ccc;
    position: relative;
    bottom: 1px;
  }
}

//modal 按钮
.modal_btn_j {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .ant-btn {
    font-size: 16px;
    border-radius: 5px;
    width: 91px;
    height: 39px;
    &:nth-child(1) {
      color: #888888;
    }
    &:nth-child(2) {
      background: #3e78ed;
      border-radius: 5px;
      color: #fff;
      margin-left: 30px;
    }
  }
}

//red
.red {
  color: red;
  margin-right: 2px;
}

//取消排序按钮
.cancel_sort {
  border: 1px solid #3e78ed !important;
  border-radius: 3px;
  width: 90px;
  height: 32px;
  margin-right: 30px;
  span {
    color: #3e78ed;
    font-size: 14px;
  }
}
//确定排序
.sure_sort {
  background: #3e78ed !important;
  border-radius: 3px;
  color: #fff;
  margin-right: 20px;
  border: solid 1px #3e78ed !important;
}
//保存按钮
.save {
  color: #3e78ed;
  font-size: 12px;
}

//tab切换按钮
.tabs {
  .ant-tag {
    margin: 0 30px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    font-family: Noto Sans SC;
    font-style: normal;
    font-weight: 500;
    background: #fff;
    color: #333;
    position: relative;
    line-height:58px;
    &.active {
      color: #3e78ed;
      &:before{
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        bottom: 0;
        background: #3E78ED;
      }
    }
  }
}

.ant_blue{
  background-color: #3E78ED !important;
  border: solid 1px #3E78ED !important;
  color: #fff !important;
}

.blue{
  color:#3E78ED ;
}

.padd {
  padding: 16px 20px;
  background-color: #fff;
  height: 100%;
}

.p20 {
  padding: 20px;
}

.m20 {
  margin-bottom: 20px;
}

.bg_fff {
  background-color: #fff;
}
.pos_r{
  position: relative;
}

.layout-right{
  min-height: calc(100vh - 121px) !important;
}
.resource_box{
  height: 100%;
  padding-left: 200px;
}
.publicstyle{
  width: 100%;
  height: 100%;
  // overflow: hidden;
  .top {
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #eeeeee;
    line-height: 60px;
    display: flex;
    align-items: center;
    .add_btn {
      margin-right: 30px;
    }
    .sort {
      cursor: pointer;
    }
    .top_r {
      display: flex;
      justify-content: flex-end;
      .ant-input {
        width: 50%;
        margin-right: 20px;
      }
      .reset_btn {
        margin-right: 12px;
      }
    }
    .top_l{
      display: flex;
      .ant-input {
        width: 50%;
        margin-right: 20px;
      }
      .reset_btn {
        margin-right: 12px;
      }
    }
  }
}
:focus {
  outline: none;
}
.global_table{
  border: 1px solid #f3f3f3;
  .ant-table-body{
    min-height: 266px;
  }
  .ant-table-placeholder{
    width: 100%;
    position: absolute;
    top:60px;
    left: 0;
    height:200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-pagination-options {
    position: absolute;
    right: 100%;
  }
  .ant-table-pagination.ant-pagination{
    position: relative;
   // left: 8%;
    margin-right:15px;
  }
 .ant-pagination li, .ant-pagination a{
   border: none;
 }
}
.global_table .ant-table-fixed-right .ant-table-fixed .ant-table-thead tr th:first-child span{
  display: block;
}
.Grounding_position{
  position: absolute;
  bottom: 16px;
  left: 24px;
  .Grounding_select{
    margin:0 28px 0 10px;
    cursor: pointer;
  }
  .ant-btn-primary{
    font-family: Noto Sans SC;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #3E78ED !important;
    background: #fff !important;
  }
  .ant_share{
    margin-left: 20px;
  }
}
//文本框定位
.sold-box{
  position: relative;
  .ant-input{
    padding: 15px 20px;
  }
  .suffixStyle{
    position:absolute;
    right:10px;
    userSelect:none;
    bottom:10px
  }
}
//重置按钮样式
.btn_box{
  .ant-btn[disabled]{
    border: 1px solid #d9d9d9;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    background: #fff!important;
    color: #888888!important;
    border-radius: 3px;
  }
  .ant-btn{
    border: 1px solid #3E78ED;
    border-radius: 3px;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    cursor: pointer;
    color: #3E78ED;
  }
}
/*资源详情页面start*/
.trans_box {
  width: 100%;
  background: #f6f7fb;
  padding-bottom: 80px;
  .header {
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    height: 60px;
    display: flex;
    align-items: center;
    margin-bottom: 43px;
    .back {
      width: 70px;
      cursor: pointer;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      .back-icon{
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
    box-shadow: 0px 4px 15px rgba(45, 64, 101, 0.08);
    .title {
      flex: 1;
      display: flex;
      justify-content: center;
      h2 {
        color: #2a283d;
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
  .show_box {
    padding: 0px 70px 40px;
    box-sizing: border-box;
    min-width: 1200px;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    .left {
      flex: 1;
      max-width: calc(100% - 400px);
      background: #fff;
      .watch_bottom {
        width: 100%;
        height: 55px;
        color: #0d1731;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 20px;
        border-right: 1px solid #f3f3f3;
        .iconphoto{
          background-image: url(https://fj.cveducloud.com/public_resource/static_src/cvec-front-img/cvec-space-front-geren/zhihuijiaoxue/userempty.png);
          background-repeat: no-repeat;
          background-size: cover;
          width: 25px;
          height: 25px;
          margin-right:5px;
          border-radius: 50%;
        }
        .end {
          display: flex;
          justify-content: flex-end;
        }
      }

    }
    .right {
      width: 400px;
      padding: 30px 19px 0px 19px;
      .right_top {
        border-bottom: 1px solid rgba(224, 224, 224, 0.5);
        padding-bottom: 27px;
        width: 100%;
        color: #333333;
        margin-bottom: 20px;
        .title {
          width: 100%;
          display: flex;
          h2 {
            color: #333333;
            font-size: 20px;
            font-weight: 550px;
            margin-right: 16px;
          }
        }
        .auth {
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 14px;
          img {
            background-image: url(https://fj.cveducloud.com/public_resource/static_src/cvec-front-img/cvec-space-front-geren/zhihuijiaoxue/userempty.png);
            background-repeat: no-repeat;
            background-size: cover;
            width: 25px;
            height: 25px;
            margin-right:10px;
            border-radius: 50%;
          }
        }
        .affiliation{
          .affiname{
            position: relative;
            &:after{
              content: ">";
              margin: 0 5px;
            }

          }
        }
        .tag {
          margin-top: 12px;
          span {
            height: 30px;
            padding: 0px 10px;
            line-height: 30px;
            background: #e2ecff;
            border-radius: 3px;
            color: #3e78ed;
            display: inline-block;
          }
        }
      }
      .right_bottom {
        width: 100%;
        margin-bottom: 30px;
        h2 {
          color: #333333;
          margin-bottom: 11px;
          font-size: 14px;
          position: relative;
          padding-left: 12px;
          font-weight: bold;
          display: flex;
          justify-content: space-between;
          align-items: center;
          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 10%;
            width: 3px;
            border-radius: 1px;
            background: #3e78ed;
            height: 80%;
          }
        }
        p {
          color: #333333;
          margin-bottom: 0;
        }
        .morebtn{
          background: #FFFFFF;
          border: 1px solid #EBEBEB;
          box-sizing: border-box;
          border-radius: 5px;
          font-family: Alibaba PuHuiTi;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height:28px;
          height: 30px;
          text-align: center;
          color: #888888;
          float: right;
        }
      }
    }
  }
  .action_bttom {
    width: 100%;
    height: 80px;
    box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .action {
      margin-right: 80px;
      .ant-btn {
        margin-right: 30px;
      }
      .blue {
        color: #3e78ed;
        border: solid 1px #3e78ed;
      }
    }
    .sold-btn{
      border: 1px solid #3E78ED;
      border-radius: 5px;
      font-family: Noto Sans SC;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
      color: #3E78ED;
      background: #fff;
    }
    .share-btn{
      background: #3E78ED;
      border-radius: 5px;
      font-family: Noto Sans SC;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
      color: #FFFFFF;

    }
  }
  .xiajia_box{
    width: 100%;
    position: relative;
    p, span{
      margin: 0;
      padding: 0;
    }
    .xiajia_cont{
      box-sizing: border-box;
      position: relative;
      margin-left: 10px;
      z-index: 0;
      padding:0 0 30px 20px;
      &:last-child{
        /* .xiajia_header:after{
           border: none;
         }*/
        &:after{
          border: 0px dashed #fff;
        }
      }
      &:after{
        position: absolute;
        content: "";
        width: 1px;
        height: 100%;
        border: 1px dashed #FA6400;
        top: 5px;
        left: -2px;
      }

      .xiajia_header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px !important;
        position: relative;
        &:after{
          position: absolute;
          content: "";
          width: 8px;
          height: 8px;
          border-radius: 50%;
          border: 2px solid #FA6400;
          top: 5px;
          left: -25px;
          z-index: 99;
          background: #fff;
        }
        .xiajia_time{
          font-family: Noto Sans SC;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          display: flex;
          align-items: center;
          color: #333333;
        }
        .xiajia_name{
          font-family: Noto Sans SC;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          display: flex;
          align-items: center;
          color: #888888;
          min-width: 75px;
        }
      }
      .xiajia_desc{
        font-family: Noto Sans SC;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        color: #333333;
      }

    }
  }
}


/*资源详情页面end*/
/*分类菜单start*/
.navtop {
  display: flex;

  .left {
    margin-right: 30px;
    line-height: 30px;
    color: #2A283D;
    font-size: 14px;
  }

  .left_border {
    line-height: 30px;
    color: #E0E0E0;

  }

  //.center {
  //    flex: 6;
  //    margin: 0 14px;
  //
  //}

  .right {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    .reset_btn{
      margin-right: 20px;
    }
  }
  .classity{
    flex: 6;
    margin: 0 14px;
    .ant-radio-button-wrapper{
      border: none;
      box-shadow: none;
      outline: none;
      font-size: 14px;
    }
    .ant-radio-button-wrapper:not(:first-child)::before{
      width: 0;
    }
    .ant-radio-button-wrapper-checked{
      background: #E2ECFF;
      border-radius: 3px;
      font-family: Alibaba PuHuiTi;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #3E78ED;
      outline: none;
      &:hover{
        background: #E2ECFF;
        color: #3E78ED;
        box-shadow: none;
        border-radius: 3px;
        outline: none;
        font-size: 14px;
      }
    }
  }
  .pack-unfold{
    display: inline-block;
    width: 50px;
    cursor: pointer;
    line-height: 32px;
    .pack {
      position: relative;
      color: #3E78ED;
      &:after{
        position: absolute;
        content: "";
        background: url("../assets/img/grounding/pack.png") no-repeat;
        width: 10px;
        height: 6px;
        background-size: 10px 6px;
        right:-20px;
        top: 7px;
      }
    }
    .unfold{
      position: relative;
      color: #3E78ED;
      &:after{
        position: absolute;
        content: "";
        background: url("../assets/img/grounding/unfold.png") no-repeat;
        width: 10px;
        height: 6px;
        background-size: 10px 6px;
        right:-20px;
        top: 7px;
      }
    }

  }
}
/*分类菜单end*/
/*弹框start*/
.modal_box {
  .ant-col-5 {
    text-align: center;
  }
  .ant-form-explain {
    padding-left: 21%;
  }
  &.roleModal{
    .ant-modal-content {
      width: 100% !important;
    }
    .ant-modal-body{
position: relative;
      padding: 0;
      &:before{
        content: '';
        width: 1px;
        height: 100%;
        background: #EBEBEB;
        position: absolute;
        right: 41.2%;
        top: 0;
      }
    }
  }
}
/*弹框end*/
//文本框定位
.sold-box{
  position: relative;
  //.ant-input{
  //  padding: 15px 20px 15px 10px;
  //}
  .suffixStyle{
    position:absolute;
    right:-50px;
    userSelect:none;
    bottom:-8px
  }
}
.training_modal_box .ant-form-item-label {
  margin-right: 15px;
}
//实训的样式
.publicBox{
  padding: 16px 20px;
  background-color: #fff;
  height: 100%;
}
.practTrain_modal_box{
  .modal_btn_j .ant-btn{
    min-width: 90px;
    width: auto;
    &:nth-child(1){
      background: #FFFFFF;
      border: 1px solid #3E78ED;
      box-sizing: border-box;
      border-radius: 5px;
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
      text-align: center;
      min-width: 90px;
      color: #3E78ED;
    }
  }
}
.training-tit{
  font-family: Noto Sans SC;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #333333;
  padding: 20px 15px 20px 30px;
  position: relative;
  background: #fff;
  margin-bottom: 20px;
  &:before{
    content: '';
    width: 4px;
    height: 18px;
    background: linear-gradient(148.89deg, #6EA8FF 2.69%, #485AFE 113.17%);
    border-radius: 10px;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}
//全局modal 垂直水平居中
.ant-modal-wrap{
  display: flex;
  align-items: center;
  .ant-modal{
    top:0;
  }
}