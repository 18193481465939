.filter_box {
    width: 100%;
    padding: 20px;
    padding-top: 4px;
    border-bottom: 1px solid #EEEEEE;
    min-width: 1050px;

    >div {
        display: flex;
        justify-content: space-between;

        &:nth-child(2) {
            margin-top: 20px;
        }

        .l_filter {
            display: flex;

            >div {
                display: flex;
                align-items: center;

                &:not(:last-child) {
                    margin-right: 20px;
                }

                flex: 1;

                >span {
                    margin-right: 20px;
                    white-space: nowrap;
                }
            }
        }
    }



    .r_filter {
        display: flex;
        align-items: center;

        :global {
            .ant-btn {
                margin-right: 20px;

                &:nth-child(1) {
                    color: #888888;
                }

                &:nth-child(2) {
                    color: #FFFFFF;
                    background: #3E78ED;
                    border-color: #3E78ED;
                }
            }
        }

        >span {
            cursor: pointer;
            color: #3E78ED;
            font-size: 12px;
            display: flex;
            align-items: center;

            svg {
                margin-left: 4px;
            }

            i {
                font-style: normal;
            }
        }
    }

    div {
        >span {
            color: #333333;
            font-size: 14px;
        }

        :global {
            .ant-select {
                width: 230px;
                height: 40px;
            }

            .ant-select-selection,
            .ant-select-selection__rendered {
                height: 40px;
                line-height: 40px;
            }

            .ant-input {
                width: 230px;
                height: 40px;
            }
        }
    }
}