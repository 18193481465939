.MaxLengthInputFC {
    display: inline-flex;
    position: relative;

    .MaxLengthInputFC_input {
        padding-right: 50px;

        & ~ .MaxLengthInputFC_count {
            align-items: center;
            line-height: 100%;
        }
    }


    &._textarea {
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding-bottom: 18px;
        transition: all .3s;

        &._focus {
            border-color: #40a9ff;
            border-right-width: 1px !important;
            box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
        }

        .MaxLengthInputFC_textarea {
            border: 0;

            &:focus {
                border: 0;
                box-shadow: none;
            }
        }
    }

    .MaxLengthInputFC_textarea {
        resize: none;
        margin-bottom: 0;

        & ~ .MaxLengthInputFC_count {
            align-items: flex-end;
        }
    }

    .MaxLengthInputFC_count {
        position: absolute;
        top: 0;
        right: 10px;
        display: flex;
        height: 100%;
        white-space: nowrap;
        color: #888;
        line-height: 1.5em;
        font-size: 14px;

        > span:nth-of-type(3) {
            color: #000;
        }
    }

    .MaxLengthInputFC_addonAfter {
        position: absolute;
        top: 0;
        right: 8px;
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
    }

}
